import React from "react"
import { graphql } from "gatsby"

import { SEO } from "../helpers"

import { H1, Paragraph } from "../elements"
import { Frame } from "../layout"
import { Breadcrumb, Header } from "../compositions"

import { ContaxtQuery, GraphCms_Page } from "../../../graphql-types"

type Props = {
  data: ContaxtQuery
}

function ContactPage({ data }: Props) {
  const page = data.graphCmsPage as GraphCms_Page

  return (
    <Frame>
      <SEO page={page} />
      <Header className="z-10" />
      <div className="bg-gradient">
        <div className="relative max-w-screen-lg mx-auto pt-16 pb-40 px-4">
          <Breadcrumb breadcrumb={page.breadcrumb} className="mb-10" />
          <H1 className="text-4xl font-bold mb-4">Bize Ulaşın</H1>
          <Paragraph className="mb-7">
            Siz de yurt dışında yaşıyorsanız ve hikayenizi paylaşmak
            istiyorsanız lütfen bize aşağıdaki mail adresinden ulaşın. En kısa
            zamanda geri dönüş sağlayacağız.
          </Paragraph>
          <Paragraph className="mb-7">
            Ayrıca, röportajlara eklememizi istediğiniz sorular veya merak
            ettiğiniz herhangi bir şey olursa bizimle iletişime geçmekten lütfen
            çekinmeyin :)
          </Paragraph>
          <Paragraph className="mb-7">
            Mail adresimiz:{" "}
            <a href="mailto:iletisim@cizzgi.com" className="text-purple">
              iletisim@cizzgi.com
            </a>
          </Paragraph>
          <Paragraph>Teşekkürler😇</Paragraph>
        </div>
      </div>
    </Frame>
  )
}

export default ContactPage

export const query = graphql`
  query Contaxt {
    graphCmsPage(url: { pathname: { eq: "/iletisim" } }) {
      ...PageFields
    }
  }
`
